import * as React from 'react';
import { MenuItemLink, usePermissions } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import { styled } from '@material-ui/core';
import DevicesIcon from '@material-ui/icons/SpeakerPhone';
import CamerasIcon from '@material-ui/icons/CameraAlt';
import GatesIcon from '@material-ui/icons/Wifi';
import UnitsIcon from '@material-ui/icons/Home';
import TypeIcon from '@material-ui/icons/SettingsInputComponent';
import DataIcon from '@material-ui/icons/Storage';
import UsersIcon from '@material-ui/icons/Person';
import AssignmentIcon from '@material-ui/icons/Assignment';

const Div = styled('div')({
    paddingTop: '2rem',
});

export const Menu = () => {
    const { permissions } = usePermissions();
    return (
        <Div>
                <MenuItemLink to="/devices" primaryText="Zařízení" leftIcon={<DevicesIcon />}/>
                <MenuItemLink to="/cameras" primaryText="Kamery" leftIcon={<CamerasIcon />}/>
                <MenuItemLink to="/units" primaryText="Bytové jednotky" leftIcon={<UnitsIcon />}/>
                {permissions === 'admin' && <MenuItemLink to="/unit_templates" primaryText="Šablony byt. jednotek" leftIcon={<AssignmentIcon />}/>}
                {permissions === 'admin' && <MenuItemLink to="/users" primaryText="Uživatelé" leftIcon={<UsersIcon />}/>}
                {permissions === 'admin' && <MenuItemLink to="/device_types" primaryText="Typy zařízení" leftIcon={<TypeIcon />}/>}
                {permissions === 'admin' && <MenuItemLink to="/data_types" primaryText="Typy dat" leftIcon={<TypeIcon />}/>}
                <MenuItemLink to="/data" primaryText="Data" leftIcon={<DataIcon />}/>
        </Div>
    );
}