module.exports = function (messages) {

    messages.ra.action.export = 'Exportovat';
    messages.ra.action.unselect = 'Odznačit';
    messages.ra.navigation.page_rows_per_page = 'Záznamů na stránce';
    messages.ra.navigation.skip_nav = 'Přeskočit';
    messages.ra.page.empty = 'Žádná data k zobrazení';
    messages.ra.auth.user_menu = "Užvatelské menu";

    return messages;
};