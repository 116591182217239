import { FieldGuesser, ListGuesser, CreateGuesser, InputGuesser, EditGuesser, ShowGuesser} from "@api-platform/admin";
import React from "react";
import { ImageInput, ImageField, TextField, ReferenceInput, AutocompleteInput, SelectInput, ReferenceField, ReferenceManyField, Datagrid, SimpleShowLayout, FileInput, FileField } from "react-admin";

const PreviewImage = ({ record, source }) => {
    if (typeof (record) == "string") {
        record = {
            [source]: record
        }
    }
    return <ImageField record={record} source={source} />
}

const UnitTemplatesList = (props) => (
    <ListGuesser {...props}>
        <FieldGuesser source="name" />
    </ListGuesser>
);

const UnitTemplatesShow = (props) => (
    <ShowGuesser {...props}></ShowGuesser>
);

const UnitTemplatesCreate = (props) => (
    <CreateGuesser {...props}>
        <InputGuesser source="name"/>
        <ImageInput source="image">
            <ImageField source="src" title="title" />
        </ImageInput>
    </CreateGuesser>
);

const UnitTemplatesEdit = (props) => (
    <EditGuesser {...props}>
        <InputGuesser source="name"/>
    </EditGuesser>
);

export default {
    list: UnitTemplatesList,
    create: UnitTemplatesCreate,
    edit: UnitTemplatesEdit,
    show: UnitTemplatesShow
};