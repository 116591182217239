import { FieldGuesser, ListGuesser, CreateGuesser, InputGuesser, EditGuesser, ShowGuesser} from "@api-platform/admin";
import React from "react";
import { TextField, ReferenceInput, AutocompleteInput, SelectInput, ReferenceField, ReferenceManyField, Datagrid } from "react-admin";

const CamerasList = (props) => (
    <ListGuesser {...props}>
        <FieldGuesser source="source" />
        <ReferenceField label="Unit name" source="unit" reference="units" link={true}>
            <TextField source="name" />
        </ReferenceField>
    </ListGuesser>
);

const CamerasShow = (props) => (
    <ShowGuesser {...props}>
        <FieldGuesser source="source" addLabel={true}/>
        <ReferenceField label="Unit" source="unit" reference="units" link={true} addLabel={true}>
            <TextField source="name" />
        </ReferenceField>
    </ShowGuesser>
);

const CamerasCreate = (props) => (
    <CreateGuesser {...props}>
        <InputGuesser source="source"/>
        <ReferenceInput label="Unit" source="unit" reference="units" filterToQuery={searchText => ({name: searchText})}>
            <AutocompleteInput optionText="name"/>
        </ReferenceInput>
    </CreateGuesser>
);

const CamerasEdit = (props) => (
    <EditGuesser {...props}>
        <InputGuesser source="source"/>
        <ReferenceInput label="Unit" source="unit" reference="units" filterToQuery={searchText => ({name: searchText})}>
            <AutocompleteInput optionText="name"/>
        </ReferenceInput>
    </EditGuesser>
);

export default {
    list: CamerasList,
    create: CamerasCreate,
    edit: CamerasEdit,
    show: CamerasShow
};