import { FieldGuesser, ListGuesser, CreateGuesser, InputGuesser, EditGuesser, ShowGuesser} from "@api-platform/admin";
import React from "react";
import { TextField, ReferenceInput, AutocompleteInput, SelectInput, ReferenceField, ReferenceManyField, Datagrid, SimpleShowLayout } from "react-admin";

const UnitsList = (props) => (
    <ListGuesser {...props}>
        <FieldGuesser source="name" />
        <ReferenceField label="Template" source="template" reference="unit_templates" link={true} addLabel={true}>
            <TextField source="name" />
        </ReferenceField>
    </ListGuesser>
);

const UnitsShow = (props) => (
    <ShowGuesser {...props}>
        <FieldGuesser source="name" addLabel={true}/>
        <FieldGuesser source="template" addLabel={true}/>
        <FieldGuesser source="users" addLabel={true}/>
        <FieldGuesser source="units" addLabel={true}/>
    </ShowGuesser>
);

const UnitsCreate = (props) => (
    <CreateGuesser {...props}>
        <InputGuesser source="name"/>
        <InputGuesser source="template"/>
        <InputGuesser source="users"/>
    </CreateGuesser>
);

const UnitsEdit = (props) => (
    <EditGuesser {...props}>
        <InputGuesser source="name"/>
        <InputGuesser source="template"/>
        <InputGuesser source="users"/>
    </EditGuesser>
);

export default {
    list: UnitsList,
    create: UnitsCreate,
    edit: UnitsEdit,
    show: UnitsShow
};