import { FieldGuesser, ListGuesser, CreateGuesser, InputGuesser, EditGuesser, ShowGuesser} from "@api-platform/admin";
import * as React from "react";
import { cloneElement } from "react";
import { sanitizeListRestProps, DateField, TextField, DateTimeInput, ReferenceInput, AutocompleteInput, SelectInput, ReferenceField, ReferenceManyField, Datagrid, List, TopToolbar } from "react-admin";
import { ReferenceArrayInput, SelectArrayInput, Filter } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    input: {
        minWidth: '150px',
    },
});

const DataFilters = (props) => {
    const classes = useStyles();
    return <Filter {...props} >
        <ReferenceArrayInput className={classes.input} label="Zařízení" source="device" reference="devices" alwaysOn>
            <SelectArrayInput optionText="name"/>
        </ReferenceArrayInput>
        <ReferenceArrayInput className={classes.input} label="Typ události" source="data_type" reference="data_types" alwaysOn>
            <SelectArrayInput optionText="name"/>
        </ReferenceArrayInput>
        <DateTimeInput label="Čas od" source="timestamp[after]" ampm={false} alwaysOn/>
        <DateTimeInput label="Čas do" source="timestamp[before]" options={{ ampm: false }} alwaysOn/>
    </Filter>;
};

const ListActions = ({className, resource, filters, displayedFilters, filterValues, showFilter, ...rest}) => (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        {filters && cloneElement(filters, {resource, showFilter, displayedFilters, filterValues, context: 'button',})}
    </TopToolbar>
);

const DataList = (props) => (
    <List {...props} filters={<DataFilters />} actions={<ListActions />}>
        <Datagrid>
        <FieldGuesser source="value" />
        <DateField source="timestamp" showTime options={{ hour12: false }} />
        <ReferenceField label="Device" source="device" reference="devices" link={false}>
            <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="Data type" source="data_type" reference="data_types" link={false}>
            <TextField source="name" />
        </ReferenceField>
        </Datagrid>
    </List>
);

export default {
    list: DataList
};