import jwt_decode from 'jwt-decode';
export default {
    login: ({ username, password }) => {
        const request = new Request(
            `${process.env.REACT_APP_API_ENTRYPOINT}/authentication_token`,
            {
                method: "POST",
                body: JSON.stringify({ username, password }),
                headers: new Headers({ "Content-Type": "application/json" }),
            }
        );
        return fetch(request)
            .then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(({ token }) => {
                const decodedToken = jwt_decode(token);
                localStorage.setItem("token", token);
                localStorage.setItem('exp', decodedToken.exp );
                localStorage.setItem('permissions', decodedToken.roles.includes('ROLE_ADMIN') ? 'admin' : 'user' );
            });
    },
    logout: () => {
        localStorage.removeItem("token");
        return Promise.resolve();
    },
    checkAuth: () => {
        try {
            if (
                !localStorage.getItem("token") ||
                new Date().getTime() / 1000 >
                localStorage.getItem("exp")
            ) {
                return Promise.reject();
            }
            return Promise.resolve();
        } catch (e) {
            // override possible jwtDecode error
            return Promise.reject();
        }
    },
    checkError: (err) => {
        if ([401, 403].includes(err?.status || err?.response?.status)) {
            localStorage.removeItem("token");
            return Promise.reject();
        }
        return Promise.resolve();
    },
    getPermissions: () => {
        const role = localStorage.getItem('permissions');
        return role ? Promise.resolve(role) : Promise.reject();
    }
};