import { FieldGuesser, ListGuesser, CreateGuesser, InputGuesser, EditGuesser, ShowGuesser} from "@api-platform/admin";
import React from "react";
import { TextField, ReferenceInput, AutocompleteInput, SelectInput, ReferenceField, SelectArrayInput, PasswordInput } from "react-admin";

const UsersList = (props) => (
    <ListGuesser {...props}>
        <FieldGuesser source="username" />
    </ListGuesser>
);

const UsersShow = (props) => (
    <ShowGuesser {...props}>
        <FieldGuesser source="username" addLabel={true}/>
        <FieldGuesser source="email" addLabel={true}/>
        <FieldGuesser source="phone" addLabel={true}/>
        <FieldGuesser source="roles" addLabel={true}/>
        <FieldGuesser source="units" addLabel={true}/>
    </ShowGuesser>
);

const UsersCreate = (props) => (
    <CreateGuesser {...props}>
        <InputGuesser source="username"/>
        <InputGuesser source="email"/>
        <InputGuesser source="phone"/>
        <PasswordInput source="password"/>
        <SelectArrayInput source="roles" choices={[
            { id: 'ROLE_USER', name: 'User'},
            { id: 'ROLE_GATE', name: 'Gate'},
            { id: 'ROLE_ADMIN', name: 'Admin'},
        ]}/>
        <InputGuesser source="units"/>
    </CreateGuesser>
);

const UsersEdit = (props) => (
    <EditGuesser {...props}>
        <InputGuesser source="username"/>
        <InputGuesser source="email"/>
        <InputGuesser source="phone"/>
        <PasswordInput source="password"/>
        <SelectArrayInput source="roles" choices={[
            { id: 'ROLE_USER', name: 'User'},
            { id: 'ROLE_GATE', name: 'Gate'},
            { id: 'ROLE_ADMIN', name: 'Admin'},
        ]}/>
        <InputGuesser source="units"/>
    </EditGuesser>
);

export default {
    list: UsersList,
    create: UsersCreate,
    edit: UsersEdit,
    show: UsersShow
};