import React from "react";
import { Redirect, Route } from "react-router-dom";
import { HydraAdmin, hydraDataProvider as baseHydraDataProvider, fetchHydra as baseFetchHydra, useIntrospection, ResourceGuesser } from "@api-platform/admin";
//import parseHydraDocumentation from "@api-platform/api-doc-parser/lib/hydra/esm/parseHydraDocumentation";
import parseHydraDocumentation from "@api-platform/api-doc-parser/lib/esm/hydra/parseHydraDocumentation";
import authProvider from "./authProvider";

import data from "./Entity/data.js";
import device from "./Entity/device.js";
import camera from "./Entity/camera.js";
import unit from "./Entity/unit.js";
import unit_template from "./Entity/unit_template.js";
import user from "./Entity/user.js";
import device_type from "./Entity/device_type.js";

import MyLayout from './MyLayout';

import polyglotI18nProvider from 'ra-i18n-polyglot';
import czechMessages from 'ra-language-czech';
import extendsLanguageMessages from './translation/czech.js';

const i18nProvider = polyglotI18nProvider(() => extendsLanguageMessages(czechMessages), 'cs');

import { Resource } from "react-admin";

const entrypoint = process.env.REACT_APP_API_ENTRYPOINT + '/api';

const getHeaders = () => localStorage.getItem("token") ? {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
} : {};

const fetchHydra = (url, options = {}) =>
    baseFetchHydra(url, {
        ...options,
        headers: getHeaders,
    });

const RedirectToLogin = () => {
    const introspect = useIntrospection();

    if (localStorage.getItem("token")) {
        introspect();
        return <></>;
    }
    return <Redirect to="/login" />;
};

const apiDocumentationParser = async (entrypoint) => {
    try {
        const { api } = await parseHydraDocumentation(entrypoint, { headers: getHeaders });
        return { api };
    } catch (result) {
        if (result.status === 401) {
            // Prevent infinite loop if the token is expired
            localStorage.removeItem("token");

            return {
                api: result.api,
                customRoutes: [
                    <Route path="/" component={RedirectToLogin} />
                ],
            };
        }

        throw result;
    }
};

const dataProvider = baseHydraDataProvider(entrypoint, fetchHydra, apiDocumentationParser, false);

export default () => (
    <HydraAdmin
        dataProvider={ dataProvider }
        authProvider={ authProvider }
        i18nProvider={ i18nProvider }
        entrypoint={ entrypoint }
        layout={ MyLayout }
        >

        <ResourceGuesser name="devices" list={device.list} create={device.create} edit={device.edit} show={device.show} />
        <ResourceGuesser name="units" list={unit.list} create={unit.create} edit={unit.edit} show={unit.show} />
        <ResourceGuesser name="unit_templates" list={unit_template.list} create={unit_template.create} edit={unit_template.edit} show={unit_template.show} />
        <ResourceGuesser name="cameras" list={camera.list} create={camera.create} edit={camera.edit} show={camera.show} />
        <ResourceGuesser name="users" list={user.list} create={user.create} edit={user.edit} show={user.show} />

        <ResourceGuesser name="device_types" list={device_type.list} create={device_type.create} edit={device_type.edit} show={device_type.show}/>
        <ResourceGuesser name="data_types"/>
        <ResourceGuesser name="data" list={data.list} />

    </HydraAdmin>
);