import { FieldGuesser, ListGuesser, CreateGuesser, InputGuesser, EditGuesser, ShowGuesser} from "@api-platform/admin";
import React from "react";
import { TextField, ReferenceInput, AutocompleteInput, SelectInput, ReferenceField, ReferenceManyField, Datagrid } from "react-admin";

const DevicesList = (props) => (
    <ListGuesser {...props}>
        <FieldGuesser source="name" />
        <FieldGuesser source="mac" />
        <ReferenceField label="Unit name" source="unit" reference="units" link={true}>
            <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="Device type name" source="type" reference="device_types" link={false}>
            <TextField source="name" />
        </ReferenceField>
    </ListGuesser>
);

const DevicesShow = (props) => (
    <ShowGuesser {...props}>
        <FieldGuesser source="name" addLabel={true}/>
        <FieldGuesser source="mac" addLabel={true}/>
        <ReferenceField label="Device type" source="type" reference="device_types" link={false}>
            <TextField source="name" addLabel={true}/>
        </ReferenceField>
        <ReferenceField label="Unit" source="unit" reference="units" link={true} addLabel={true}>
            <TextField source="name" />
        </ReferenceField>
        <ReferenceManyField label="Data" target="data" reference="data" link={true} addLabel={true} fullWidth={true}>
            <Datagrid rowClick="show" >
                <TextField source="timestamp" />
                <ReferenceField label="Data type name" source="data_type" reference="data_types" link={false}>
                    <TextField source="name" />
                </ReferenceField>
                <TextField source="value" />
            </Datagrid>
        </ReferenceManyField>
    </ShowGuesser>
);

const DevicesCreate = (props) => (
    <CreateGuesser {...props}>
        <InputGuesser source="name"/>
        <InputGuesser source="mac"/>
        <ReferenceInput label="Device type" source="type" reference="device_types" filterToQuery={searchText => ({name: searchText})}>
            <SelectInput optionText="name"/>
        </ReferenceInput>
        <ReferenceInput label="Unit" source="unit" reference="units" filterToQuery={searchText => ({name: searchText})}>
            <AutocompleteInput optionText="name"/>
        </ReferenceInput>
    </CreateGuesser>
);

const DevicesEdit = (props) => (
    <EditGuesser {...props}>
        <InputGuesser source="name"/>
        <InputGuesser source="mac"/>
        <ReferenceInput label="Device type" source="type" reference="device_types" filterToQuery={searchText => ({name: searchText})}>
            <SelectInput optionText="name"/>
        </ReferenceInput>
        <ReferenceInput label="Unit" source="unit" reference="units" filterToQuery={searchText => ({name: searchText})}>
            <AutocompleteInput optionText="name"/>
        </ReferenceInput>
    </EditGuesser>
);

export default {
    list: DevicesList,
    create: DevicesCreate,
    edit: DevicesEdit,
    show: DevicesShow
};