import { FieldGuesser, ListGuesser, CreateGuesser, InputGuesser, EditGuesser, ShowGuesser} from "@api-platform/admin";
import React from "react";
import { TextField, ReferenceInput, AutocompleteInput, SelectInput, ReferenceField, ReferenceManyField, Datagrid, SimpleShowLayout, FileInput, FileField } from "react-admin";

const DeviceTypesList = (props) => (
    <ListGuesser {...props}>
        <FieldGuesser source="name" />
    </ListGuesser>
);

const DeviceTypesShow = (props) => (
    <ShowGuesser {...props}>
        <FieldGuesser source="name" addLabel={true}/>
        <FieldGuesser source="code" addLabel={true}/>
    </ShowGuesser>
);

const DeviceTypesCreate = (props) => (
    <CreateGuesser {...props}>
        <InputGuesser source="name"/>
        <InputGuesser source="code"/>
    </CreateGuesser>
);

const DeviceTypesEdit = (props) => (
    <EditGuesser {...props}>
        <InputGuesser source="name"/>
        <InputGuesser source="code"/>
    </EditGuesser>
);

export default {
    list: DeviceTypesList,
    create: DeviceTypesCreate,
    edit: DeviceTypesEdit,
    show: DeviceTypesShow
};